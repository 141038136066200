import React, { useCallback, useState, useMemo, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { GrClose } from 'react-icons/gr';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import { GA_FORM_COMPLETE, GA_PROFILE_INFORMATION } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import { account as accountMock } from 'helpers/mocks/mockData';
import { logEventToDataLayer } from 'helpers/utils/analytics';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import { useAccount, useCart } from 'frontastic';
import { logout } from 'frontastic/actions/account';
import { validateEmail } from '../../../../../helpers/utils/emailValidator';
import PasswordMatchRequirements from '../../../../../helpers/utils/passwordMatchRequirements';
import MaxLimit from '../../../aafes/Modals/maxLimitModal';
import UpdateProfileModal from '../../../aafes/Modals/updateProfileConfirmation';
import Field from '../field';
const LoginModal = dynamic(() => import('../../../aafes/Modals/Login/login'));

const General = () => {
  //i18n messages
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const confirmationMessage = formatAccountMessage({
    id: 'update.Profile',
    defaultMessage: 'Profile Updated Successfully.',
  });
  //next/router
  const router = useRouter();

  //account data
  const { account, update, updateCustomerPreferences } = useAccount();
  const [showInformation, setShowInformation] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [validPassword, setValidPassowrd] = useState(true);
  const [newPasswordValue, setNewPasswordValue] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [updatedProfile, setUpdatedProfile] = useState(false);
  const [updateAccountErrorMessage, setupdateAccountErrorMessage] = useState('');
  const [previousPasswordErrorMessage, setpreviousPasswordErrorMessage] = useState(null);
  const [loginModal, setLoginModal] = useState(false);
  const [triggerRetrieveUsername, setTriggerRetrieveUsername] = useState(false);
  const [passwordChangeData, setPasswordChangeData] = useState({});

  const handleNewPasswordChange = (event) => {
    const value = event.target.value;
    setNewPasswordValue(value);
    clearErrors('newPassword');
  };
  //Form data
  const [data, setData] = useState({
    firstName: account?.firstName ?? '',
    lastName: account?.lastName ?? '',
    email: account?.email ?? '',
    phone: account?.profilePhone ?? '',
    userName: account?.userName ?? '',

    locale: router.locale || router.defaultLocale,
  });

  useEffect(() => {
    setData({
      firstName: account?.firstName ?? '',
      lastName: account?.lastName ?? '',
      email: account?.email ?? '',
      phone: account?.profilePhone ?? '',
      userName: account?.userName ?? '',
      locale: router.locale || router.defaultLocale,
    });
  }, [showInformation]);

  const [inEdit, setInEdit] = useState<number[]>([]);

  const startEdit = useCallback(
    (index: number) => {
      setInEdit([...inEdit, index]);
    },
    [inEdit],
  );

  const stopEdit = useCallback(
    (index: number) => {
      setInEdit(inEdit.filter((i) => i !== index));
    },
    [inEdit],
  );

  const updateAccountData = async () => {
    try {
      const updateAccountData = await update({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        profilePhone: data?.phone,
      });
      if (updateAccountData) {
        if (data?.phone !== account?.profilePhone) {
          const orderSubscriptionPhone = await updateCustomerPreferences(
            undefined,
            undefined,
            'orderSubscriptionPhone',
            data?.phone,
          );
          if (orderSubscriptionPhone?.errorCode === 500) {
            return setupdateAccountErrorMessage(orderSubscriptionPhone?.body);
          }
        }
        if (data?.email !== account?.email) {
          const orderSubscriptionEmail = await updateCustomerPreferences(
            undefined,
            undefined,
            'orderSubscriptionEmail',
            data?.email,
          );
          if (orderSubscriptionEmail?.errorCode === 500) {
            return setupdateAccountErrorMessage(orderSubscriptionEmail?.body);
          }
        }
        setUpdatedProfile(true);
        setSuccessMessage(confirmationMessage);
        setupdateAccountErrorMessage('');
        setTimeout(() => {
          setUpdatedProfile(false);
          setSuccessMessage(null);
        }, 3000);
      }
    } catch (error) {
      setupdateAccountErrorMessage(error.message);
      setUpdatedProfile(false);
      setSuccessMessage(null);
    }
  };

  const sections = useMemo(
    () => [
      {
        headline: formatAccountMessage({ id: 'profile', defaultMessage: 'Profile' }),
        subline: formatAccountMessage({
          id: 'profile.desc',
          defaultMessage: 'This information will be displayed publicly so be careful what you share.',
        }),
        fields: [
          {
            id: 'firstname',
            name: 'firstName',
            label: formatMessage({ id: 'firstName', defaultMessage: 'First Name' }),
            value: account?.firstName ?? '',
            type: 'text',
            options: [],
            defaultValue: '',
            required: true,
            editable: true,
            maxLength: 19,
            onInput: (event) => {
              // Remove any numeric characters from the input
              event.target.value = event.target.value.replace(/[^A-Za-z]+/g, '');
            },
          },
          {
            id: 'lastname',
            name: 'lastName',
            label: formatMessage({ id: 'lastName', defaultMessage: 'Last Name' }),
            value: account?.lastName ?? '',
            type: 'text',
            options: [],
            defaultValue: '',
            required: true,
            editable: true,
            maxLength: 20,
          },
          {
            id: 'email',
            name: 'email',
            label: formatMessage({ id: 'email', defaultMessage: 'Email' }),
            value: account?.email ?? '',
            type: 'email',
            options: [],
            defaultValue: '',
            required: true,
            editable: true,
            maxLength: 40,
          },
          {
            id: 'profilePhone',
            name: 'phone',
            label: formatMessage({ id: 'phone', defaultMessage: 'phone' }),
            value: account?.profilePhone ?? '',
            type: 'text',
            options: [],
            defaultValue: '',
            required: true,
            editable: true,
            maxLength: 15,
            minLength: 10,
          },
        ],
        onSubmit: async () => {
          try {
            const emailUpdated = data?.email !== account?.email;
            const phoneUpdated = data?.phone !== account?.profilePhone;
            if (emailUpdated || phoneUpdated) {
              setTriggerRetrieveUsername(true);
              setLoginModal(true);
            } else {
              const updateAccountData = await update({
                firstName: data?.firstName,
                lastName: data?.lastName,
                email: data?.email,
                profilePhone: data?.phone,
              });
              if (updateAccountData?.errorCode === 500) {
                setupdateAccountErrorMessage(updateAccountData?.body);
              } else {
                setUpdatedProfile(true);
                setupdateAccountErrorMessage('');
                const dataLayerData = {
                  form_name: 'Edit Personal Info',
                  content_id: 'Profile',
                };
                logEventToDataLayer(GA_FORM_COMPLETE, dataLayerData);
              }
            }
            if (data?.firstName !== account?.firstName) {
              await updateCustomerPreferences(
                undefined,
                undefined,
                undefined,
                undefined,
                data?.firstName,
                undefined,
                undefined,
                undefined,
              );
            }
          } catch (error) {
            setupdateAccountErrorMessage('An unexpected error occurred. Please try again.');
          }
        },
      },
    ],
    [formatAccountMessage, router, data, account],
  );

  //Put all sections in edit mode
  const startEditAll = useCallback(() => {
    setInEdit(sections.map((_, index) => index));
  }, [sections]);

  //Stop editting all sections
  const stopEditAll = useCallback(() => {
    setInEdit([]);
  }, []);

  //Input change
  const [fieldErrors, setFieldErrors] = useState({});
  function validateField(field, data) {
    if (field.name === 'firstName' && !data[field.name]) {
      return formatAccountMessage({ id: 'enterFirstName', defaultMessage: 'Please enter first name.' });
    } else if (field.name === 'lastName' && !data[field.name]) {
      return formatAccountMessage({ id: 'enterLastName', defaultMessage: 'Please enter last name.' });
    } else if (field.name === 'phone') {
      const phoneNumberRegex = /^(?:\+1\s?)?(\d{10,15})$/;
      if (data[field.name].length === 0) {
        return formatAccountMessage({ id: 'enterPhone', defaultMessage: 'Please enter phone number.' });
      } else if (data[field.name].length < 10 || data[field.name].length > 15) {
        return formatAccountMessage({ id: 'enterValidPhone', defaultMessage: 'Please enter a valid phone number.' });
      } else if (!phoneNumberRegex.test(data[field.name]) && !data[field.name].startsWith('+')) {
        return formatAccountMessage({ id: 'enterValidPhone', defaultMessage: 'Please enter a valid phone number.' });
      }
    } else if (field.name === 'email') {
      if (!data[field.name]) {
        return formatAccountMessage({ id: 'enterEmail', defaultMessage: 'Please enter email address.' });
      } else if (!validateEmail(data[field.name])) {
        return formatAccountMessage({ id: 'enterValidEmail', defaultMessage: 'Please enter valid email address.' });
      }
    }

    return null;
  }

  const handleChange = useCallback(
    (name: string, value: string) => {
      // Perform length validation
      let error = null;

      if (name === 'firstName' && value.length === 0) {
        error = formatAccountMessage({ id: 'enterFirstName', defaultMessage: 'Please enter first name.' });
      } else if (name === 'lastName' && value.length === 0) {
        error = formatAccountMessage({ id: 'enterLastName', defaultMessage: 'Please enter last name.' });
      } else if (name === 'email' && value.length === 0) {
        error = formatAccountMessage({ id: 'enterEmail', defaultMessage: 'Please enter email address.' });
      } else if (name === 'phone' && value.length === 0) {
        error = formatAccountMessage({ id: 'onlyPhoneAccept', defaultMessage: 'CONUS-only phone numbers accepted.' });
      }

      // Update the fieldErrors state
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error,
      }));

      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    },
    [setData, setFieldErrors],
  );

  //Handle submission
  const handleSubmit = useCallback(
    async (index: number) => {
      let formIsValid = true;

      sections.forEach((section, index) => {
        section.fields.forEach((field) => {
          const fieldError = validateField(field, data);

          if (fieldError) {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              [field.name]: fieldError,
            }));
            formIsValid = false;
          }
        });
      });

      if (formIsValid) {
        sections[index].onSubmit();
        stopEdit(index);
      }
    },
    [inEdit, stopEdit, sections],
  );

  //Submit all sections
  const handleSubmitAll = useCallback(async () => {
    let formIsValid = true;

    sections.forEach((section, index) => {
      section.fields.forEach((field) => {
        const fieldError = validateField(field, data);

        if (fieldError) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [field.name]: fieldError,
          }));
          formIsValid = false;
        }
      });
    });

    // If the form is valid, submit the sections
    if (formIsValid) {
      stopEditAll();
      for (const section of sections) await section.onSubmit();
    }
  }, [sections, stopEditAll, data]);

  const {
    register,
    handleSubmit: submit,
    formState: { errors },
    watch,
    reset,
    clearErrors,
  } = useForm();
  const { accountChangePassword } = useAccount();
  const [isEditMode, setIsEditMode] = useState(false);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordRequirements, setShowPasswordRequirements] = useState(false);

  const validatePassword = (password) => {
    const mustDifferentFromUsername = password !== account?.userName;
    const doesNotContainSpaces = !password?.includes(' ');
    const hasMinimumLength = password?.length >= 6;
    const hasMaximumLength = password?.length <= 15;
    const hasUppercase = /[A-Z]/.test(password);
    const hasDigit = /[0-9]/.test(password);
    const allowedSpecialCharacters = `!^[]’@#$%&*'"()_+=|}{;:<,.>/?”`;
    let hasAllowedSpecialCharacters = false;

    for (const char of allowedSpecialCharacters) {
      if (password?.includes(char)) {
        hasAllowedSpecialCharacters = true;
        break;
      }
    }
    return {
      mustDifferentFromUsername,
      doesNotContainSpaces,
      hasMinimumLength,
      hasMaximumLength,
      hasUppercase,
      hasDigit,
      hasAllowedSpecialCharacters,
    };
  };
  const isValid = () => {
    return (
      passwordValidation.mustDifferentFromUsername &&
      passwordValidation.doesNotContainSpaces &&
      passwordValidation.hasMinimumLength &&
      passwordValidation.hasMaximumLength &&
      passwordValidation.hasUppercase &&
      passwordValidation.hasDigit &&
      passwordValidation.hasAllowedSpecialCharacters
    );
  };
  const passwordValidation = validatePassword(password);

  const changeAccountPassword = async (data) => {
    try {
      const changePasswordResponse: any = await accountChangePassword(data);
      if (changePasswordResponse?.errorCode) {
        setErrorMessage(changePasswordResponse?.body?.errorMessage);
        setpreviousPasswordErrorMessage(changePasswordResponse?.body);
        if (changePasswordResponse?.errorCode === 500 && changePasswordResponse?.isLocked) {
          logout(account?.accountId);
          router.push('/');
        }
        setValidPassowrd(false);
        setUpdatedProfile(false);
        setLoginModal(false);
        setTriggerRetrieveUsername(false);
      } else {
        setShowPasswordRequirements(false);
        setErrorMessage('');
        setpreviousPasswordErrorMessage('');
        setLoginModal(false);
        setTriggerRetrieveUsername(false);
        setSuccessMessage(
          formatAccountMessage({
            id: 'successMessage',
            defaultMessage: 'Password has been changed successfully.',
          }),
        );
        reset();
        const dataLayerParams = {
          form_name: 'Edit Password',
          content_id: 'Profile',
        };
        logEventToDataLayer(GA_FORM_COMPLETE, dataLayerParams);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data) => {
    setPasswordChangeData(data);
    setTriggerRetrieveUsername(true);
    setLoginModal(true);
  };

  const handleEdit = () => {
    setIsEditMode(true);
    setShowPasswordRequirements(false);
  };

  const handleCancel = () => {
    setChangePassword(false);
    setShowPasswordRequirements(false);
    reset();
    setErrorMessage('');
    setpreviousPasswordErrorMessage('');
    setSuccessMessage(false);
  };

  const handlePasswordChange = (event) => {
    setShowPasswordRequirements(true);
    const newPassword = event.target.value;
    setPassword(newPassword);
    setShowPasswordRequirements(true);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleCloseModal = () => {
    setChangePassword(false);
    setSuccessMessage('');
  };

  function closeConfirmationModal() {
    setUpdatedProfile(false);
    setShowInformation(false);
  }
  const isOldPasswordSameAsNewPassword = (oldPassword, newPassword) => {
    return oldPassword !== newPassword;
  };

  const { setCartProductDetailEventSent, studioConfig, data: cartList } = useCart();
  const [monetateConfig, setMonetateConfig] = useState(null);
  const [monetateSent, setMonetateSent] = useState(false);
  useEffect(() => {
    if (studioConfig?.monetateConstants) {
      try {
        const monetateConfig = JSON?.parse(studioConfig?.monetateConstants?.replace(/'/g, '"'));
        setMonetateConfig(monetateConfig);
      } catch (error) {
        console.error('Errorparsing projectConfig:', error);
      }
    }
  }, [studioConfig]);

  useEffect(() => {
    const processMonetate = async () => {
      const callMonetate = async () => {
        setMonetateSent(true);
        await sendToMonetate(
          {
            page: 'profile',
            cart: {
              ...cartList,
            },
          },
          monetateConfig,
        );
      };
      if (account && monetateConfig && !monetateSent) {
        await callMonetate();
        setCartProductDetailEventSent(true);
      }
    };
    const fetchData = async () => {
      await processMonetate();
    };
    fetchData();
  }, [account, monetateConfig]);

  return (
    <div className="flex w-full flex-col">
      <div aria-labelledby="profile-section" className="flex justify-between">
        <h3 id="profile-section" className="text-[21px] font-bold leading-6 text-gray-900">
          {formatAccountMessage({ id: 'profile', defaultMessage: 'Profile' })}
        </h3>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-4 md:grid-cols-2">
        <div aria-labelledby="personal-info-section" className="rounded border border-slate-300 p-4">
          <h3 id="personal-info-section" className="mb-6 font-bold text-gray-900">
            {formatMessage({ id: 'personalInformation', defaultMessage: 'Personal Information' })}
          </h3>

          <div className="mb-4 grid grid-cols-4 gap-2 text-sm text-gray-900">
            <div className="col-span-1 text-[#666666]" aria-hidden="true">
              {formatMessage({ id: 'firstname', defaultMessage: 'First Name :' })}
            </div>
            <div className="col-span-3 font-bold" aria-label="First Name">
              {account?.firstName}
            </div>
            <div className="col-span-1 text-[#666666]	" aria-hidden="true">
              {formatMessage({ id: 'lastName', defaultMessage: 'Last Name :' })}
            </div>
            <div className="col-span-3 font-bold" aria-label="Last Name">
              {account?.lastName}
            </div>

            <div className="col-span-1 text-[#666666]	" aria-hidden="true">
              {formatMessage({ id: 'email', defaultMessage: 'Email :' })}
            </div>
            <div className="col-span-3 font-bold" aria-label="Email">
              {account?.email}
            </div>

            <div className="col-span-1 text-[#666666]	" aria-hidden="true">
              {formatMessage({ id: 'phone', defaultMessage: 'Phone :' })}
            </div>
            <div className="col-span-3 font-bold" aria-label="Phone">
              {account?.profilePhone}
            </div>
          </div>
          <div className="text-sm">
            <button
              type="button"
              className="text-[#043C63] hover:underline"
              onClick={() => {
                setShowInformation(!showInformation);
              }}
            >
              {formatAccountMessage({ id: 'editPersonalInformation', defaultMessage: 'Edit Personal Information' })}
            </button>
          </div>
        </div>
        <div aria-labelledby="password-security-section" className="rounded border border-slate-300 p-4">
          <h3 id="password-security-section" className="mb-6 font-bold text-gray-900">
            {formatAccountMessage({ id: 'password&Security', defaultMessage: 'Password & Security' })}
          </h3>
          <div className="mb-4 grid grid-cols-4 gap-2 text-sm text-gray-900">
            <div className="col-span-1 text-[#666666]	" aria-hidden="true">
              {formatAccountMessage({ id: 'userName', defaultMessage: 'Username :' })}
            </div>
            <div className="col-span-3 font-bold" aria-label="Username">
              {data.userName}
            </div>

            <div className="col-span-1 text-[#666666]	" aria-hidden="true">
              {formatAccountMessage({ id: 'password', defaultMessage: 'Password :' })}
            </div>
            <div className="col-span-3 font-bold" aria-label="Password">
              *********
            </div>
          </div>
          <div className="text-sm">
            <button type="button" className="text-[#043C63] hover:underline" onClick={() => setChangePassword(true)}>
              {formatAccountMessage({ id: 'editPassword', defaultMessage: 'Edit Password' })}
            </button>
          </div>
        </div>
      </div>

      {showInformation && (
        <section aria-label="Account Sections">
          {sections.map((section, index) => (
            <div
              role="region"
              aria-labelledby={`sectionHeading${index}`}
              className="mt-3 rounded-sm border-gray-200 px-2 pt-10 lg:border lg:px-10 lg:pb-10"
              key={index}
            >
              <div className="space-y-1">
                <div aria-labelledby="section-headline" className="flex items-center justify-between">
                  <h2 id="section-headline" className="text-lg font-medium leading-6 text-gray-900 dark:text-light-100">
                    {section.headline}
                  </h2>
                  <div className="hidden lg:block">
                    <div className="flex items-center divide-x-2 divide-solid divide-gray-200 text-sm">
                      <span className="pr-4">
                        <button
                          type="submit"
                          className="rounded-md px-3  font-medium text-accent-400 transition hover:text-accent-600 focus:outline-none"
                          onClick={() => handleSubmit(index)}
                        >
                          {formatMessage({ id: 'save', defaultMessage: 'Save' })}
                        </button>
                        {loginModal && (
                          <LoginModal
                            loginModal={true}
                            closeModal={() => {
                              setLoginModal(false);
                              setTriggerRetrieveUsername(false);
                            }}
                            closeAccountFlyout={() => {
                              setLoginModal(false);
                              setTriggerRetrieveUsername(false);
                            }}
                            loginCallBack={() => console.log('Login Callback')}
                            continueAsGuestEligible={false}
                            isRememberMeSessionExpired={false}
                            triggerRetrieveUsername={triggerRetrieveUsername}
                            existingUser={account}
                            isProfileUpdate={true}
                            updateAccountData={updateAccountData}
                          />
                        )}
                        {''}|{''}
                        <button
                          type="button"
                          onClick={() => {
                            {
                              setShowInformation(!showInformation);
                              setShowPasswordRequirements(false);
                              setFieldErrors({});
                              setupdateAccountErrorMessage('');
                            }
                          }}
                          className="rounded-md px-3 font-medium text-accent-400 transition hover:text-accent-600 focus:outline-none"
                        >
                          {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <p className="max-w-2xl py-4 text-sm text-gray-500">{section.subline}</p>
              </div>
              {updateAccountErrorMessage && <p className="text-red-900">{updateAccountErrorMessage}</p>}
              <div className="mt-6 grid grid-cols-1 gap-6 lg:grid-cols-2">
                {section.fields.map((field, fieldIndex) => (
                  <div key={fieldIndex}>
                    <label htmlFor={field.id} className="flex items-center">
                      {field.label} <span className="text-red-500">*</span>
                    </label>
                    <Field
                      key={fieldIndex}
                      id={field.id}
                      value={field.value}
                      type={field.type}
                      options={field.options}
                      defaultValue={field.defaultValue}
                      required={field.required}
                      onKeyPress={(e) => {
                        const nextValue = e.target.value + e.key;
                        const regex = /^\p{L}[-'\\. \s\p{L}]*$/u;
                        const blockedCharacters = '-()[]{}';
                        if ((field.name === 'firstName' || field.name === 'lastName') && !regex.test(nextValue)) {
                          e.preventDefault();
                        } else if (
                          field.name === 'phone' &&
                          (e.key === ' ' ||
                            blockedCharacters.includes(e.key) ||
                            e.key.match(/[A-Za-z]/) ||
                            (e.key === '+' && e.target.selectionStart !== 0) ||
                            (e.target.value.startsWith('+') && !/^\d$/.test(e.key)))
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(val) => {
                        val = val.trim().replace(/\s+/g, ' ');
                        handleChange(field.name, val);
                      }}
                      error={fieldErrors[field.name]}
                      maxLength={field.maxLength}
                    />

                    {fieldErrors[field.name] && <span className="text-red-500">{fieldErrors[field.name]}</span>}
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="mt-10 block px-2 lg:hidden">
            <div className="flex items-center gap-3">
              <button
                onClick={handleSubmitAll}
                className="grow rounded-sm bg-accent-400 p-3 font-medium text-white transition duration-150 ease-out hover:bg-accent-600"
              >
                {formatMessage({ id: 'save', defaultMessage: 'Save' })}
              </button>
              {}|{}
              <button
                onClick={() => {
                  setShowInformation(!showInformation);
                  setFieldErrors({});
                  setupdateAccountErrorMessage('');
                }}
                className="grow rounded-sm bg-accent-400 p-3 font-medium text-white transition duration-150 ease-out hover:bg-accent-600"
              >
                {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
              </button>
            </div>
          </div>
        </section>
      )}
      {changePassword && (
        <div
          role="region"
          aria-labelledby="passwordSecurityHeading"
          className="mt-3 rounded-sm border-gray-200 px-2 pt-10 lg:border lg:px-10 lg:pb-10"
        >
          <div className="space-y-1">
            <form onSubmit={submit(onSubmit)}>
              <div className="flex justify-between">
                <div aria-labelledby="password-section" className="flex-wrap md:flex">
                  <h3 id="password-section">
                    {formatMessage({ id: 'passwordSecurity', defaultMessage: 'Password & Security' })}{' '}
                  </h3>
                  <div className="m-0 md:ml-4 ">
                    {' '}
                    <span className="text-red-500" aria-hidden="true">
                      * Required
                    </span>
                  </div>
                </div>{' '}
                <div className="flex items-center">
                  <div className="flex space-x-2">
                    <button
                      type="submit"
                      className={`rounded ${isValid() ? '' : 'cursor-not-allowed'}`}
                      disabled={!isValid()}
                      aria-label={formatMessage({ id: 'save', defaultMessage: 'Save' })}
                    >
                      {formatMessage({ id: 'save', defaultMessage: 'Save' })}
                    </button>
                    {loginModal && (
                      <LoginModal
                        loginModal={true}
                        closeModal={() => {
                          setLoginModal(false);
                          setTriggerRetrieveUsername(false);
                        }}
                        closeAccountFlyout={() => {
                          setLoginModal(false);
                          setTriggerRetrieveUsername(false);
                        }}
                        loginCallBack={() => console.log('Login Callback')}
                        continueAsGuestEligible={false}
                        isRememberMeSessionExpired={false}
                        triggerRetrieveUsername={triggerRetrieveUsername}
                        existingUser={account}
                        isPasswordChange={true}
                        passwordChangeData={passwordChangeData}
                        changeAccountPassword={changeAccountPassword}
                      />
                    )}
                    <p className="mx-2">|</p>
                    <button type="button" className="text-gray-500" onClick={handleCancel}>
                      {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                    </button>
                  </div>
                </div>
              </div>
              {errorMessage && <p className="text-[#cd0808]">{errorMessage}</p>}
              {previousPasswordErrorMessage && <p className="text-[#cd0808]">{previousPasswordErrorMessage}</p>}
              {successMessage && <MaxLimit closeModal={handleCloseModal} customMessage={successMessage} />}
              <div className="mt-6 grid grid-cols-1 gap-6 lg:grid-cols-2">
                <div>
                  <label htmlFor="username" className="mb-3 block text-sm font-medium capitalize text-gray-600">
                    {formatMessage({ id: 'username', defaultMessage: 'Username' })}
                  </label>
                  <input
                    type="text"
                    id="username"
                    {...register('username')}
                    className="block w-full rounded-sm border-neutral-400 p-3 text-neutral-600 shadow-sm transition duration-150 ease-out focus:border-accent-400 focus:ring-accent-400 disabled:cursor-not-allowed disabled:text-gray-400 sm:text-sm"
                    value={account?.userName}
                    disabled
                  />
                  {errors.username && (
                    <span className="text-red-500" role="alert">
                      {formatMessage({ id: 'thisFieldRequered', defaultMessage: 'This field is required' })}
                    </span>
                  )}
                </div>

                <div className="relative">
                  <label htmlFor="oldPassword" className="mb-3 block text-sm font-medium capitalize text-gray-600">
                    {formatMessage({ id: 'currentPassword', defaultMessage: 'Current Password' })}
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="flex">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      id="oldPassword"
                      autoComplete="current-password"
                      {...register('oldPassword', { required: true })}
                      className={`block w-full rounded-sm  p-3 ${
                        errors.oldPassword ? 'border-red-500' : 'border-neutral-400'
                      } text-neutral-600 shadow-sm transition duration-150 ease-out focus:border-accent-400 focus:ring-accent-400 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-400 sm:text-sm`}
                    />
                    <button
                      className="absolute right-3 top-11 flex items-center text-lg"
                      type="button"
                      onClick={togglePasswordVisibility}
                      aria-label={showPassword ? 'Hide password' : 'Show password'}
                    >
                      <div className="mr-1">{showPassword ? <RiEyeOffFill /> : <RiEyeFill />}</div>
                      <div className="text-sm">{showPassword ? 'HIDE' : 'SHOW'}</div>
                    </button>
                  </div>
                  {errors.oldPassword && (
                    <span className="text-red-500" role="alert">
                      {formatMessage({ id: 'thisFieldRequered', defaultMessage: 'This field is required' })}
                    </span>
                  )}
                </div>

                <div className="relative">
                  <label htmlFor="newPassword" className="mb-3 block text-sm font-medium capitalize text-gray-600">
                    {formatMessage({ id: 'newPassword', defaultMessage: 'New Password' })}
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="flex">
                    <input
                      type={showNewPassword ? 'text' : 'password'}
                      id="newPassword"
                      maxLength={15}
                      autoComplete="new-password"
                      {...register('newPassword', {
                        required: true,
                        validate: {
                          isNotSameAsOld: (value) => isOldPasswordSameAsNewPassword(watch('oldPassword'), value),
                        },
                      })}
                      className={`block w-full rounded-sm p-3 ${
                        (errors.newPassword && !newPasswordValue) || errors?.newPassword?.type === 'isNotSameAsOld'
                          ? 'border-red-500'
                          : 'border-neutral-400'
                      }  text-neutral-600 shadow-sm transition duration-150 ease-out focus:border-accent-400 focus:ring-accent-400 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-400 sm:text-sm`}
                      onChange={(e) => {
                        handlePasswordChange(e);
                        handleNewPasswordChange(e);
                      }}
                    />
                    <button
                      className="absolute right-3 top-11 flex items-center text-lg"
                      type="button"
                      onClick={toggleNewPasswordVisibility}
                      aria-label={showNewPassword ? 'Hide password' : 'Show password'}
                    >
                      <div className="mr-1">{showNewPassword ? <RiEyeOffFill /> : <RiEyeFill />}</div>
                      <div className="text-sm">{showNewPassword ? 'HIDE' : 'SHOW'}</div>
                    </button>
                  </div>
                  {passwordRequirements && PasswordMatchRequirements(password)}
                  <div>
                    {errors.newPassword && errors.newPassword.type === 'required' && !newPasswordValue && (
                      <span className="text-red-500" role="alert">
                        {formatMessage({ id: 'thisFieldRequered', defaultMessage: 'This field is required' })}
                      </span>
                    )}
                    {errors.newPassword && errors.newPassword.type === 'isNotSameAsOld' && (
                      <span className="text-red-500" role="alert">
                        {formatAccountMessage({
                          id: 'newPasswordSameAsOld',
                          defaultMessage: 'The new password is the same as the old password',
                        })}
                      </span>
                    )}
                  </div>
                </div>

                <div className=" relative">
                  <label htmlFor="confirmPassword" className="mb-3 block text-sm font-medium capitalize text-gray-600">
                    {formatMessage({ id: 'confirmNewPassword', defaultMessage: 'Confirm New Password' })}
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="flex">
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      maxLength={15}
                      id="confirmPassword"
                      autoComplete="confirm-password"
                      {...register('confirmPassword', {
                        required: true,
                        validate: (value) => value === watch('newPassword'),
                      })}
                      className={`block w-full rounded-sm border ${
                        errors.confirmPassword ? 'border-red-500' : 'border-neutral-400'
                      } p-3 text-neutral-600 shadow-sm transition duration-150 ease-out focus:border-accent-400 focus:ring-accent-400 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-400 sm:text-sm`}
                      onClick={() => setShowPasswordRequirements(false)}
                    />
                    <button
                      className="absolute right-3 top-11 flex items-center text-lg"
                      type="button"
                      onClick={toggleConfirmPasswordVisibility}
                      aria-label={showConfirmPassword ? 'Hide password' : 'Show password'}
                    >
                      <div className="mr-1">{showConfirmPassword ? <RiEyeOffFill /> : <RiEyeFill />}</div>
                      <div className="text-sm">{showConfirmPassword ? 'HIDE' : 'SHOW'}</div>
                    </button>
                  </div>
                  {errors.confirmPassword && errors.confirmPassword.type === 'required' && (
                    <span className="text-red-500" role="alert">
                      {formatMessage({ id: 'thisFieldRequered', defaultMessage: 'This field is required' })}
                    </span>
                  )}
                  {errors.confirmPassword && errors.confirmPassword.type === 'validate' && (
                    <span className="text-red-500" role="alert">
                      {formatMessage({ id: 'passwordDoNotMatch', defaultMessage: 'Passwords do not match' })}
                    </span>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      <UpdateProfileModal
        closeModal={closeConfirmationModal}
        updatedProfile={updatedProfile}
        message={confirmationMessage}
      />
    </div>
  );
};

export default General;
